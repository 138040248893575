import React, { useState } from 'react';
import { Form }  from '../../form/index.tsx';
import { useTranslation } from 'react-i18next';
import { getSession } from '../../../utils/session.js'

const SERVER_URL = process.env.REACT_APP_SERVER_URL;

const getFields = (t: Function) => [
  {
    type: 'divider',
    label: t(`experience.label`)
  }, {
    type: 'input',
    label: t(`name.label`),
    name: 'name',
    placeholder: t(`name.placeholder`),
    disclaimer: t(`name.disclaimer`),
    isDisclaimerTop: true,
    rules: [{ required: true, message: t(`name.error.required`)}]
  }, {
    type: 'upload',
    label: t(`media.label`),
    name: 'media',
    placeholder: t(`media.placeholder`),
    disclaimer: t(`media.disclaimer`),
    isDisclaimerTop: true,
    rules: [{ required: true, message: t(`media.error.required`) }]
  }, {
    type: 'price',
    label: t(`price.label`),
    name: 'price',
    min: 0,
    placeholder: t(`price.placeholder`),
    disclaimer: t(`price.disclaimer`),
    isDisclaimerTop: true,
    rules: [{ required: true, message: t(`price.error.required`) }]
  }, {
    type: 'selector',
    label: t(`currency_code.label`),
    name: 'currency_code',
    placeholder: t(`currency_code.placeholder`),
    options: [{
      label: 'CAD', value: 'CAD'
    }, {
      label: 'USD', value: 'USD'
    }, {
      label: 'EUR', value: 'EUR'
    }],
    rules: [{ required: true, message: t(`currency_code.error.required`) }]
  }, {
    type: 'input',
    label: t(`place.label`),
    disclaimer: t(`place.disclaimer`),
    isDisclaimerTop: true,
    name: 'place',
    min: 0,
    placeholder: t(`place.placeholder`),
  }, {
    type: 'dynamic-date',
    label: t(`date.label`),
    name: 'experience_dates',
    min: 0,
    placeholder: t(`date.placeholder`),
  }, {
    type: 'input',
    label: t(`hours.label`),
    name: 'hours',
    placeholder: t(`hours.placeholder`),
    rules: [{ required: true, message: t(`hours.error.required`) }]
  }, {
    type: 'empty'
  }, {
    type: 'text-area',
    label: t(`description.label`),
    name: 'description',
    disclaimer: t(`description.disclaimer`),
    isDisclaimerTop: true,
    placeholder: t(`description.placeholder`),
    rules: [{ required: true, message: t(`description.error.required`) }]
  }, {
    type: 'text-area',
    label: t(`extra_info.label`),
    disclaimer: t(`extra_info.disclaimer`),
    isDisclaimerTop: true,
    name: 'extra_info',
    placeholder: t(`extra_info.placeholder`),
  }, {
    type: 'check',
    label: t(`extra_info.label`),
    disclaimer: t(`extra_info.disclaimer`),
    isDisclaimerTop: true,
    name: 'extra_info',
    placeholder: t(`extra_info.placeholder`),
    rules: [{ required: true, message: t(`extra_info.error.required`) }]
  }, {
    type: 'divider',
    label: t(`payment.label`)
  },
  {
    type: 'input',
    label: t(`bank_account_firstname.label`),
    name: 'bank_account_firstname',
    placeholder: t(`bank_account_firstname.placeholder`),
    rules: [{ required: true, message: t(`bank_account_firstname.error.required`)}]
  },
  {
    type: 'input',
    label: t(`bank_account_lastname.label`),
    name: 'bank_account_lastname',
    placeholder: t(`bank_account_lastname.placeholder`),
    rules: [{ required: true, message: t(`bank_account_lastname.error.required`)}]
  },
  {
    type: 'input',
    label: t(`paypal_account_email.label`),
    name: 'paypal_account_email',
    placeholder: t(`paypal_account_email.placeholder`),
    rules: [
      { required: true, message: t(`paypal_account_email.error.required`)},
      { type: 'email', message: t(`paypal_account_email.error.email`) }
    ]
  }, {
    type: 'radio',
    label: t(`cancellation_policy.label`),
    name: 'cancellation_policy',
    placeholder: t(`cancellation_policy.placeholder`),
    options: [{
      label: 'People can cancel until 7 days before the experience start time for a full refund.', value: '7d'
    }, {
      label: 'People can cancel until 24 hours before the experience start time for a full refund', value: '24h'
    }],
    rules: [{ required: true, message: t(`cancellation_policy.error.required`) }]
  },
]
const getActions = (t: Function, onLoad: Function, addExperience: Function, onSubmit: Function, onCancel: Function) => ({
  submit: {
    label: t(`submit.label`),
    action: (values: any) => {
      values.media = values.media.map((image: any) => ({
        source: image.response.Location,
        type: image.response.type,
        mimetype: image.response.mimetype
      }))
       onLoad()
       addExperience(values)
        .then((response) => onSubmit(response))
    }
  },
})

interface Props {
  paymentInfo?: any
  onSubmit: Function
  onCancel: Function
}


export const CreateExperience: React.FC<Props> = ({ paymentInfo, onSubmit, onCancel }) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false)
  
  const addNewExperience = (form) => {
    setLoading(true)
    return fetch(`${SERVER_URL}/public/experience`,{
      method: 'POST',
      headers:{
        'Content-Type': 'application/json',
        'auth-token': getSession()
      },
      body: JSON.stringify({
        ...form
      })
    })
    .then(response => {
      return {...response.json(), status:  response.status}
    })
    .then(({data, status}) => {
      setLoading(false)
      return status
    })
    .catch((error) => {
      setLoading(false)
      return error
    });
  }

  const onLoad = () => setLoading(true)
  const onLoadedAndSubmit = (params) => {
    setLoading(false)
    onSubmit(params)
  }

  const fields = getFields((key:string) => t(`create-experience.${key}`))
  const actions = getActions((key:string) => t(`create-experience.${key}`), onLoad, addNewExperience, onLoadedAndSubmit, onCancel)
  
  const onSelectInputMap = (form: any, value: any) => {
    form.setFieldsValue({
      city: value.municipality,
      state: value.state,
    });
  }
  return (
    <Form
      fields={fields}
      actions={actions}
      loading={loading}
      layout='vertical'
      initialValues={{
        ...paymentInfo
      }}
    />
  );
};
