import './ExperienceDetails.css';
import { useEffect, useRef, useState } from "react";
import { useParams, useSearchParams } from 'react-router-dom';
import { Layout, Space, Spin, Menu } from 'antd';
import { ReadOutlined } from '@ant-design/icons';

const { Header, Footer, Sider, Content } = Layout;

const SERVER_URL = process.env.REACT_APP_SERVER_URL;

function getItem(label, key, icon, children, type) {
  return {
    key,
    icon,
    children,
    label,
    type,
  };
}

const headerStyle: React.CSSProperties = {
  backgroundColor: 'white',
};

const contentStyle: React.CSSProperties = {
  margin: '20px',
  width: '100%'
};

const spinStyle: React.CSSProperties = {
  margin: '20px',
  width: '100%'
};

const siderStyle: React.CSSProperties = {
  backgroundColor: 'transparent',
  margin: '40px 20px'
};

const BlogPage = () => {
  const pageRef = useRef();
  const { id } = useParams();
  const [page, setPage] = useState(null)
  const [loading, setLoading] = useState(false)
  const [blogList, setBlogList] = useState([])
  const [searchParams, setSearchParams] = useSearchParams();

  const updateSize = () => {
    const message = {type: 'resize', name: 'custom-page', height: pageRef.current.scrollHeight};
    console.log('update size message: ', message);
    window.parent.postMessage(
      message,
      process.env.REACT_APP_PARENT_DOMAIN
    );
  }
  const differUpdateSize = () => {
    setTimeout(updateSize, 1000)
  }

  const createMarkup = () => {
    return {__html: page};
  }

  const onClickMenu = (menu) => {
    console.log('on click menu: ', menu)
    getBlogPage(menu.key)
  }

  const getBlogPage = (id) => {
    setLoading(true)
    fetch(`${SERVER_URL}/blog/${id}`)
      .then(response => response.json())
      .then(({response}) => {
        setPage((response && response.html) || '' )
      })
      .then(differUpdateSize)
      .then(() => setLoading(false))
  }
  useEffect(() => {   
    getBlogPage(id)
    const lang = searchParams.get('lang') || 'EN'

    fetch(`${SERVER_URL}/editable-blog?lang=${lang}`)
      .then(response => response.json())
      .then(({response}) => {
        console.log(response)
        setBlogList(response)
      })
      .then(differUpdateSize)
  }, [])
  
  return (
    <div ref={pageRef} className="App">
      <Space direction="vertical" style={{ width: '100%' }} size={[0, 48]}>
        
        <Layout>
          <Header style={headerStyle}></Header>
          <Layout hasSider>
            <Sider style={siderStyle}>
            {blogList && <Menu
              defaultSelectedKeys={['1']}
              defaultOpenKeys={['post']}
              mode="inline"
              onClick={onClickMenu}
              items={[
                getItem(
                  'Recent Post', 
                  'post',
                  <ReadOutlined />,
                  blogList.map((item) => getItem(item.name, item.id, null))
                )
              ]}
            />}
            </Sider>
            <Spin style={spinStyle} spinning={loading} ip="Loading...">
              <Content style={contentStyle}>
                <div dangerouslySetInnerHTML={createMarkup()} />
              </Content>
            </Spin>
          </Layout>

        </Layout>
      </Space>
    </div>
  )
  
}

export default BlogPage;
