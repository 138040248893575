
import '../App.css';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useEffect, useRef } from "react";
import { useTranslation } from 'react-i18next';

function Details() {
  const pageRef = useRef();
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const [searchParams, setSearchParams] = useSearchParams();


  useEffect(() => {
    const lang = searchParams.get('lang') || 'EN'
    if(lang === 'GER') {
      i18n.changeLanguage('ger')
    }
    window.parent.postMessage(
      {type: 'resize', height: pageRef.current.scrollHeight}, 
      process.env.REACT_APP_PARENT_DOMAIN
    );
  }, [])

  const basicSubcription = {title: 'SPANISH EXPERIENCES ACCOUNT', price: { cad: '388', eur: '289'}, legal: 'Price no include Taxes', buttonText:'1 Month', subscription: 'basic'}
  const studentSubcription = {title: 'STUDENT PLAN', price: { cad: '428', eur: '319'}, legal: 'Price no include Taxes', buttonText:'1 Month', subscription: 'student'}
  const premiumSubcription = {title: 'STUDENT PREMIUM PLAN', price: { cad: '1300', eur: '967'}, legal: 'Price no include Taxes', extra: 'DEDUCTED ONCE A MONTH', buttonText:'2 Month', subscription: 'premium'}
  
  
  const features = [
    {feature: t(`membership-programs.features.spanish-private-online-lessons`),                                       basic: '-', student: '✔', premium: '✔', classname: ''},
    {feature: t(`membership-programs.features.plan-to-learn-spanish-for-learners`),                                   basic: '-', student: '✔', premium: '✔', classname: ''},
    {feature: t(`membership-programs.features.experiences-to-improve-spanish-skills`),                                basic: '✔', student: '-', premium: '✔', classname: ''},
    {feature: t(`membership-programs.features.flexible-schedule`),                                                    basic: '-', student: '✔', premium: '✔', classname: ''},
    {feature: t(`membership-programs.features.experience-guide`),                                                     basic: '-', student: '-', premium: '✔', classname: ''},
    {feature: t(`membership-programs.features.earning-points-program`),                                               basic: '✔', student: '✔', premium: '✔', classname: ''},
    {feature: t(`membership-programs.features.placement-test`),                                                       basic: '✔', student: '✔', premium: '✔', classname: ''},
    {feature: t(`membership-programs.features.premium-code`),                                                         basic: '-', student: '-', premium: '✔', classname: ''},
    {feature: t(`membership-programs.features.no-extra-fees`),                                                        basic: '✔', student: '✔', premium: '✔', classname: ''},
    {feature: t(`membership-programs.features.no-contract`),                                                          basic: '✔', student: '✔', premium: '✔', classname: ''},
    {
      feature: '',
      basic:   <button className='button' onClick={() => navigate("/subscribe", {state: {subscription: basicSubcription.subscription, price: basicSubcription.price}})}>Log in</button>,
      student: <button className='button' onClick={() => navigate("/subscribe", {state: {subscription: studentSubcription.subscription, price: studentSubcription.price}})}>Log in</button>,
      premium: <button className='button' onClick={() => navigate("/subscribe", {state: {subscription: premiumSubcription.subscription, price: premiumSubcription.price}})}>Log in</button>,
      classname: 'button-deatils'
    },
  ]
  return (
    <div ref={pageRef} className="App">
      <div className='App-header'>
        <div className='container'>
          <h1 className='main-plan-title'><strong>{t(`membership-programs.title`)}</strong></h1>
          <div className='table-container'>
            <table style={{margin: '40px 0'}}>
              <thead>
                <tr>
                  <td className='td-feature td-membership-title'></td>
                  <td className='td-membership-title'>
                    <h2>
                      SPANISH EXPERIENCES ACCOUNT
                    </h2>
                  </td>
                  <td className='td-membership-title'>
                    <h2>
                      STUDENT PLAN
                    </h2>
                  </td>
                  <td className='td-membership-title'>
                    <h2>
                      STUDENT PREMIUM PLAN
                    </h2>
                  </td>
                </tr>
              </thead>
              <tbody>
              {features.map((element, index) => {
                const {feature, basic, student, premium, classname} = element
                return (
              <tr key={index}>
                <td className='feature'>{feature}</td>
                <td data-title={basicSubcription.title} className={`check ${classname}`}>{basic}</td>
                <td data-title={studentSubcription.title} className={`check ${classname}`}>{student}</td>
                <td data-title={premiumSubcription.title} className={`check ${classname}`}>{premium}</td>
              </tr>
              )})}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Details;
