import './ExperienceDetails.css';
import { useEffect, useRef } from "react";
import { Alert, Button } from 'antd'

const FailurePage = () => {
  const pageRef = useRef();
  useEffect(() => {    
    window.parent.postMessage(
      {type: 'resize', height: pageRef.current.scrollHeight}, 
      process.env.REACT_APP_PARENT_DOMAIN
    );
  }, [])

  return (
    
    <div ref={pageRef} className="App">
      <div className='App-header experience-detail-header'>
        <div className='container experience-detail-container' style={{display: 'flex', flexDirection: 'column'}}>
        <Alert
          message='Subscription Error'
          description={
            <div>
              <p>Please try later</p>
              
              <Button size="small" type="primary"  onClick={() => window.location = 'https://spanishtimezone.com/'}>
                Go to Home
              </Button>
            </div>
          }
          type="error"
          showIcon
        />
        </div>
      </div>
    </div>
  )
  
}

export default FailurePage;
