import React, { useState, useEffect } from 'react';
import { Form }  from '../../form/index.tsx';
import { useTranslation } from 'react-i18next';

const getFields = (t: Function, subscriptions, shortForm) => shortForm ? [{
  type: 'selector',
  label: t(`subscription_id.label`),
  name: 'subscription_id',
  placeholder: t(`subscription_id.placeholder`),
  options: subscriptions.map((option) => ({
    label: option.name, value: option.id
  })),
  rules: [
    { required: true, message: t(`subscription_id.error.required`)},
  ]
}] : [
  {
    type: 'input',
    label: t(`firstname.label`),
    name: 'firstname',
    placeholder: t(`firstname.placeholder`),
    rules: [{ required: true, message: t(`firstname.error.required`)}]
  }, {
    type: 'input',
    label: t(`lastname.label`),
    name: 'lastname',
    placeholder: t(`lastname.placeholder`),
    rules: [{ required: true, message: t(`lastname.error.required`)}]
  }, {
    type: 'input',
    label: t(`email.label`),
    name: 'email',
    placeholder: t(`email.placeholder`),
    rules: [
      { required: true, message: t(`email.error.required`) },
      { type: 'email', message: t(`email.error.email`) }
    ]
  }, {
    type: 'input',
    label: t(`phone.label`),
    name: 'phone',
    placeholder: t(`phone.placeholder`),
  }, {
    type: 'input',
    label: t(`address.label`),
    name: 'address',
    placeholder: t(`address.placeholder`),
    rules: [{ required: true, message: t(`address.error.required`)}]
  }, {
    type: 'input',
    label: t(`city.label`),
    name: 'city',
    placeholder: t(`city.placeholder`),
    rules: [{ required: true, message: t(`city.error.required`)}]
  }, {
    type: 'input',
    label: t(`state.label`),
    name: 'state',
    placeholder: t(`state.placeholder`),
    rules: [
      { required: true, message: t(`state.error.required`)},
    ]
  }, {
    type: 'input',
    label: t(`country.label`),
    name: 'country',
    placeholder: t(`country.placeholder`),
    rules: [
      { required: true, message: t(`country.error.required`)},
    ]
  }, {
    type: 'date',
    label: t(`birth.label`),
    name: 'birth',
    placeholder: t(`birth.placeholder`),
    rules: [
      { type: 'object' as const, required: true, message: t(`birth.error.required`)},
    ]
  }, {
    type: 'input',
    label: t(`recomended_by.label`),
    name: 'recomended_by',
    min: 0,
    placeholder: t(`recomended_by.placeholder`),
    disclaimer: t(`recomended_by.disclaimer`)
  }, {
    type: 'selector',
    label: t(`gender.label`),
    name: 'gender',
    placeholder: t(`gender.placeholder`),
    options: [
      {label: 'Woman', value: 'woman'},
      {label: 'Man', value: 'man'},
      {label: 'I\'d rather not say it', value: 'unknow'},
      {label: 'Other', value: 'other'}
    ],
    rules: [
      { required: true, message: t(`gender.error.required`)},
    ]
  }, {
    type: 'selector',
    label: t(`subscription_id.label`),
    name: 'subscription_id',
    placeholder: t(`subscription_id.placeholder`),
    options: subscriptions.map((option) => ({
      label: option.name, value: option.id
    })),
    rules: [
      { required: true, message: t(`subscription_id.error.required`)},
    ]
  }
]
const getActions = (t: Function, onLoad: Function, onSubmit: Function, onCancel: Function) => ({
  submit: {
    label: t(`submit.label`),
    action: (values: any) => {
      console.log(values)
       onLoad()
       onSubmit(values)
        
    }
  },
})

interface Props {
  experience_dates?: any
  onSubmit: Function
  onCancel: Function
}
const SERVER_URL = process.env.REACT_APP_SERVER_URL;

const basePath = `${SERVER_URL}/editor`
const getSubscription = () => {
  return fetch(`${basePath}/subscription`,{
    method: 'GET',
  })
  .then(catchError)
  .then((response) => (response.json()))
}

const catchError = (response: any) => {
  if(response.status!==200) {
    throw new Error(response.status)
  }
  return response
}


export const CreateSubscription: React.FC<Props> = ({ onSubmit, onCancel, experience_dates, shortForm } ) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false)
  const [subscriptions, setSubscriptions] = useState([])

  const onLoad = () => setLoading(true)
  const onLoadedAndSubmit = (values) => {
    setLoading(false)
    onSubmit(values)
  }
  useEffect(() => {
    getSubscription()
      .then(response => response.response)
      .then(setSubscriptions)
  }, []);

  const fields = getFields((key:string) => t(`create-subscription.${key}`), subscriptions, shortForm)
  if(experience_dates) {
    fields.push({
      type: 'selector',
      label: t(`create-subscription.event_date.label`),
      name: 'event_date',
      placeholder: t(`create-subscription.event_date.placeholder`),
      options: experience_dates.map((date) => {
        const d = new Date(date).toISOString().slice(0, 10)
        return { value: d, label: d }
      }),
      rules: [
        { required: true, message: t(`create-subscription.event_date.error.required`)},
      ]
    })
  }
  const actions = getActions((key:string) => t(`create-subscription.${key}`), onLoad, onLoadedAndSubmit, onCancel)
  
  return (
    <Form
      fields={fields}
      actions={actions}
      loading={loading}
    />
  );
};
