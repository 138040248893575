import './PromotionalExperiences.css';
import { useState, useEffect, useRef } from 'react';
import { imageUrl } from '../utils/image';
import { Card, Space, Button, Row, Col } from "antd";
import { useSearchParams } from 'react-router-dom';

const { Meta } = Card;

const Experience = ({id, name, level, experience_dates, description, media, price, currency_code, updateSize, redirect}) => {
  const firstImage = media.filter((resource) => resource.type === 'image')[0]
  const srcImage = firstImage ? firstImage.source: 'https://ik.imagekit.io/devkyngpq/default-img_02wKwNjUU.jpeg'
  const image = imageUrl(srcImage, 300, 200)
  const metaDescription = level !== 'undefined' && <p className='promotional-experience-text'> {level}</p>
  return (
    <Card 
      bordered={true}

      hoverable
      cover={<img alt="example" src={image} onLoad={updateSize} />}
      actions={[
        <Button type='primary' shape='round' block danger  onClick={() => redirect(`/experience?id=${id}`)}>
          GET IT
        </Button>
      ]}
    >
      <div style={{minHeight: '180px'}}>
        <Meta description={metaDescription} />
        <Meta title={(
          <h2 style={{whiteSpace: 'pre-wrap'}} className='promotional-experience-title'>{name}</h2>
        )} />
        <Meta style={{margin: '10px 0'}} description={experience_dates.map((date) => new Date(date).toDateString().concat(',').slice(0, -1))} />
      </div>
      <div style={{minHeight: '180px'}}>
        <p className='promotional-experience-text' > {description} </p>
        <p className='promotional-experience-price' >Price: ${price} {currency_code}</p>
      </div>
    </Card>
  )
}

const PromotionalExperincesPage = () => {
  const SERVER_URL = process.env.REACT_APP_SERVER_URL;
  const [experiences, setExperiences] = useState([])
  const [searchParams, setSearchParams] = useSearchParams();

  const pageRef = useRef();
  
  const updateSize = () => {
    window.parent.postMessage(
      {type: 'resize', height: pageRef.current.scrollHeight}, 
      process.env.REACT_APP_PARENT_DOMAIN
    );
  }

  const redirect = (url) => {
    window.parent.postMessage(
      { type: 'redirect', url }, 
      process.env.REACT_APP_PARENT_DOMAIN
    );
  }

  useEffect(() => {
    updateSize()
    const lang = searchParams.get('lang') || 'EN'
    fetch(`${SERVER_URL}/promotional-experience?lang=${lang}`,{
      method: 'GET',
    })
    .then((response) => response.json())
    .then((response) => setExperiences(response.response))
    .then(updateSize)
  }, [])

  return (
    <div ref={pageRef} className="App experience">
      <div className='experience-body'>
        <div className='promotional-experiences-container'>
          <Space wrap={true} direction='horizontal' align='start' size='large'>
            <Row gutter={16}>
            {experiences.map((experience, index) => (
              <Col className="gutter-row" span={6} xs={24} sm={24} md={6} lg={6} xl={6}>
                <Experience key={index} {...experience} updateSize={updateSize} redirect={redirect}/>
              </Col>
            ))}
            </Row>
          </Space>
        </div>
      </div>
    </div>
  )
}

export default PromotionalExperincesPage;
