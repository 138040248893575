import './ExperienceDetails.css';
import { useEffect, useRef, useState } from "react";
import { useParams, useSearchParams } from 'react-router-dom';

const SERVER_URL = process.env.REACT_APP_SERVER_URL;

const CustomPage = () => {
  const pageRef = useRef();
  const { name } = useParams();
  const [page, setPage] = useState(null)
  const [searchParams, setSearchParams] = useSearchParams();

  
  const updateSize = () => {
    const message = {type: 'resize', name: 'custom-page', height: pageRef.current.scrollHeight};
    console.log('update size message: ', message);
    window.parent.postMessage(
      message,
      process.env.REACT_APP_PARENT_DOMAIN
    );
  }
  const differUpdateSize = () => {
    setTimeout(updateSize, 1000)
  }

  const createMarkup = () => {
    return {__html: page};
  }

  useEffect(() => {
    const lang = searchParams.get('lang') || 'EN'
    fetch(`${SERVER_URL}/editable-page/${name}?lang=${lang}`)
      .then(response => response.json())
      .then(({response}) => {
        console.log(response)
        setPage(response.html)
      })
      .then(differUpdateSize)
  }, [])

  return (
    <div ref={pageRef} className="App">
      <div dangerouslySetInnerHTML={createMarkup()} />
    </div>
  )
  
}

export default CustomPage;
