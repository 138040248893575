import './ExperienceDetails.css';
import { useState, useEffect } from 'react';
import { useNavigate, useLocation, useParams, useSearchParams } from 'react-router-dom';
import { Rate, Button, Drawer, Layout, Avatar, List, Input, Space } from 'antd';
import { CommentOutlined, MinusOutlined, SendOutlined } from '@ant-design/icons';
import 'bootstrap/dist/css/bootstrap.min.css';
import { imageUrl } from '../utils/image'
import ImageGallery from 'react-image-gallery';
import 'react-image-gallery/styles/css/image-gallery.css'
import { useTranslation } from 'react-i18next';

const { Footer, Content } = Layout;
const { TextArea } = Input;

const data = [
  {
    title: 'Frances',
  },
  {
    title: 'Juan',
  },
  {
    title: 'Frances',
  },
  {
    title: 'Juan',
  },
];

const Experience = () => {
  const { experienceId } = useParams();
  const SERVER_URL = process.env.REACT_APP_SERVER_URL;
  const [scrollHeight, setScrollHeight] = useState(0);
  const [state, setState] = useState(null);
  const [rates, setRates] = useState(null);
  const [globalRate, setGlobalRates] = useState(null);
  const [errorMsg, setErrorMsg] = useState('');
  const [successMsg, setSuccessMsg] = useState('');
  const [loading, setLoading] = useState(false);
  const [inputs, setInputs] = useState({
    email: '',
    code: '',
    event_date: null,
  });
  const [searchParams, setSearchParams] = useSearchParams();
  const { t, i18n } = useTranslation();

  const { state: localState } = useLocation();
  const navigate =  useNavigate()
  const pageRef = (element) => {
    if(element){
      updateSize(element)
    }
  }

  const updateSize = (element) => {
    window.parent.postMessage(
      {type: 'resize', height: element.scrollHeight}, 
      process.env.REACT_APP_PARENT_DOMAIN
    );
    setScrollHeight(element.scrollHeight)
  }
  const getExperience = () => {
    const lang = searchParams.get('lang') || 'EN'
    fetch(`${SERVER_URL}/experience/${experienceId}?lang=${lang}`)
    .then(response => response.json())
    .then(({response}) => {
      setState(response)
    })
  }

  const getExperienceRates = (experienceId) => {
    fetch(`${SERVER_URL}/rate/${experienceId}`)
    .then(response => response.json())
    .then(({response}) => {
      setRates(response)
      if(response.length > 0) {
        const gr = response.reduce((acc, current) => acc + current.rate, 0)/response.length
        setGlobalRates(gr)
        const height = response.filter(rate => rate.comment && rate.comment!=='').length * 138 + response.length*58
        updateSize({scrollHeight: scrollHeight + height})
      }
    })
  }

  useEffect(() => {
    const lang = searchParams.get('lang') || 'EN'
    if(lang === 'GER') {
      i18n.changeLanguage('ger')
    }

    if(experienceId) {
      getExperience();
    } else {
      setState(localState)
      if(localState.experience_dates && localState.experience_dates[0]) {
        setInputs({
          ...inputs,
          event_date: new Date(localState.experience_dates[0]).toISOString().slice(0, 10)
        })
      }     
    }
    getExperienceRates(localState?.id | experienceId)
  }, [localState])

  const redirectToBuy = () => navigate('/experience/buy', { state })
  const handleSubmit = (event) => {
    event.preventDefault()
    setLoading(true)
    setErrorMsg('')
    setSuccessMsg('')
    fetch(`${SERVER_URL}/experience/inscription`,{
      method: 'POST',
      headers:{
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        experience_id: state.id,
        ...inputs
      })
    })
    .then(({status}) => {
      if(status === 403) {
        setErrorMsg('Incorrect code, check if your subscription allow this experience')
      }
      if(status === 409) {
        setErrorMsg('Code used Before')
      }
      if(status === 200) {
        setSuccessMsg('Congrats!!! you joined the experience')
      }
    })
    .then(() => setLoading(false))
  }

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setInputs(values => ({...values, [name]: value}))

  }

  if (!state || state === [] ) {
    return <></>
  }

  const checkExist = (value) => !!value && value!=='undefined'
  let images = state.media.filter((resource) => resource.type === 'image')
  if(!images.length) {
    images = [{
      type: 'image',
      mimetype: 'image/jpeg',
      source: 'https://ik.imagekit.io/devkyngpq/default-img_02wKwNjUU.jpeg'
    }]
  }
  return(
  <div>
    {state && <div ref={pageRef} className='experience-detail-card'>
      <h1 className='experience-detail-title'>
        {state.name}
        {state.sale && state.sale !== 'undefined' &&
        <div style={{
            backgroundColor: 'red',
            color: 'white',
            position: 'absolute',
            left: '-40px',
            top: '-20px',
            zIndex: '999',
            border: 'none',
            padding: '5px',
            borderRadius: '6px',
            fontSize: '1rem',
          }}>{t(`experience-details.sale`)}</div>
        }
      </h1>
      {globalRate && <Rate allowHalf disabled defaultValue={globalRate} />}
      <div className='experience-detail-image-text-container'>
        <ImageGallery style={{flex: 1}} items={images.map((file, index) => ({original: imageUrl(file.source, 400, 300), thumbnail: imageUrl(file.source, 100)}))}/>
        <div style={{flex: 1, padding: '20px'}}>
          <p className='experience-detail-price' >{t(`experience-details.price`)}: {state.price_message}</p>
          {checkExist(state.sale) && <p className='experience-detail-price' >{state.sale}</p> }
          <div className='experience-detail-box'>
            <div>
              <p className='experience-detail-date' ><strong>{state.experience_dates.map((date) => new Date(date).toDateString().concat(',').slice(0, -1))}</strong></p>
              <p className='experience-detail-text' ><strong>{t(`experience-details.deadline`)}: {new Date(state.inscription_deadline).toDateString().concat(',').slice(0, -1)}</strong></p>
              {checkExist(state.place) && <p className='experience-detail-text' ><strong>{t(`experience-details.monitor`)}:</strong> {state.monitor}</p>}
              <p className='experience-detail-text' >{state.hour}</p>
              {checkExist(state.place) && <p className='experience-detail-text' >{state.place}</p>}
              <p className='experience-detail-text' ><strong>{state.summary_info}</strong></p>
            </div>
            <p className='experience-detail-available' >{state.available ? '' : 'Event not available at the moment'}</p>
            <p className='experience-detail-text' >{state.hours}</p>
          </div>
        </div>
      </div>
      
      <div>
        <p className='experience-detial-tab'>{t(`experience-details.description`)}</p>
        <p className='experience-detail-text' >{state.description}</p>
        <p className='experience-detail-text' >{state.extra_info}</p>
        <form onSubmit={handleSubmit}>
      
          <div className='experience-detail-form-parent'>
          <p className='experience-detail-disclaimer'>{t(`experience-details.promotion`)}: </p>
            <div className='experience-detail-form'>
              <label className='experience-detail-form-label'>
              {t(`experience-details.email`)}:
                <input className='experience-detail-form-input' type="email" name="email" onChange={handleChange} required/>
              </label>
              <label className='experience-detail-form-label'>
              {t(`experience-details.code`)}:
                <input className='experience-detail-form-input' type="text" name="code" onChange={handleChange} required/>
              </label>
            </div>
            <button className='button' disabled={loading}>
              {loading && <i className="fa fa-spinner fa-spin"> </i>}
              {t(`experience-details.enter`)}
            </button>
            <p className='experience-detail-error'>{errorMsg}</p>
            <p className='experience-detail-success'>{successMsg}</p>
          </div>
        </form>
        <div className='experience-detail-buy-ticket'>
          <p className='experience-detail-disclaimer'>{t(`experience-details.buy`)}:</p>
          <button className='button' onClick={redirectToBuy}>
          {t(`experience-details.add-to-cart`)}
          </button>
        </div>
        
      </div>
    </div>
    }
    {rates && rates.length > 0 && <h3>People says:</h3>}
      {rates && rates.map((rate, index) => (
        <div key={index} className='experience-detail-rate'>
          <p>{rate.name}</p>
          <Rate disabled defaultValue={rate.rate} />
          {rate.comment && rate.comment!=='' && <p className='experience-detail-comment'>
            {rate.comment}
          </p>
          }
        </div>
      ))}
  </div>
  )
}

const chatStyles = {
  position: 'fixed',
  bottom: '20px',
  right: '20px',
}

const contentStyle: React.CSSProperties = {
  height: 'calc(90vh - 100px)',
  backgroundColor: 'white',
};

const revertItemStyle: React.CSSProperties = {
  flexDirection: 'row-reverse'
};

const footerStyle: React.CSSProperties = {
  textAlign: 'center',
  padding: '10px'
};

const ExperinceDatailsPage = () => {
  const [open, setOpen] = useState(false);
  const [value, setValue] = useState('');

  const showDrawer = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };

  return (
    <div className="App">
      <div className='App-header experience-detail-header'>
        <div className='container experience-detail-container'>
          <Experience />
        </div>
        {/*
        <div style={chatStyles}>
          <Button danger type="primary" shape="round" icon={<CommentOutlined />} size={'large'} onClick={showDrawer}>
            ASK
          </Button>
        </div>
        
        <Drawer title="Ask" placement="right" onClose={onClose} open={open} closeIcon={<MinusOutlined/>}>
          <Layout>
            <Content style={contentStyle}>
              <List
                itemLayout="horizontal"
                dataSource={data}
                renderItem={(item, index) => (
                  <List.Item key={index}>
                    <List.Item.Meta
                      style={index%2===0 ? revertItemStyle: {}}
                      avatar={<Avatar src={`https://xsgames.co/randomusers/avatar.php?g=pixel&key=${index}`} />}
                      title={<a href="https://ant.design">{item.title}</a>}
                      description=""
                    />
                  </List.Item>
                )}
              />
            </Content>
            <Footer style={footerStyle}>
              <Space.Compact>
                <TextArea
                  value={value}
                  onChange={(e) => setValue(e.target.value)}
                  placeholder="Controlled autosize"
                  autoSize={{ minRows: 1, maxRows: 5 }}
                />
                <Button type="primary" shape="circle" icon={<SendOutlined />} />
              </Space.Compact>
            </Footer>
          </Layout>
          
        </Drawer>

        */}
      </div>
    </div>
  )
  
}

export default ExperinceDatailsPage;
