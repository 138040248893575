import './ExperienceDetails.css';
import { useEffect, useRef, useState } from "react";
import { CreateExperience } from '../components/experiences/create/index.tsx';
import { Result, Button, Alert } from 'antd'
import { getSession } from '../utils/session'
import { useNavigate, useSearchParams } from "react-router-dom";
import { useTranslation } from 'react-i18next';

const SERVER_URL = process.env.REACT_APP_SERVER_URL;

const NewExperiencePage = () => {
  const [sent, setSent] = useState({error: false, loaded: false})
  const [session, setSession] = useState(false);
  const [paymentInfo, setPaymentInfo] = useState(null);
  const { t, i18n } = useTranslation();
  const [searchParams, setSearchParams] = useSearchParams();

  const navigate = useNavigate();

  const pageRef = useRef();

  const getPaymentInfo = () => {
    return fetch(`${SERVER_URL}/user/payment-info`,{
      method: 'GET',
      headers:{
        'Content-Type': 'application/json',
        'auth-token': getSession()
      },
    })
    .then(response => {
      if(response.status === 200) {
        return response.json()
      }
      return null;
    })
    .then(({response}) => {
      setPaymentInfo(response)
    })
  }

  useEffect(() => {  
    const lang = searchParams.get('lang') || 'EN'
    if(lang === 'GER') {
      i18n.changeLanguage('ger')
    }
    setSession(getSession()!=='')
    getPaymentInfo()
  }, [])

  useEffect(() => {  
    if (pageRef.current) {
      window.parent.postMessage(
        {type: 'resize', name: 'new-experience', height: pageRef.current.scrollHeight}, 
        process.env.REACT_APP_PARENT_DOMAIN
      );
    }
  }, [paymentInfo])
  
  const handleSubmit = (response) => {
    if(response !== 200) {
      setSent({error: true, loaded: true})
    } else {
      setSent({error: false, loaded: true})
    }
  }
  
  return (
    <div ref={pageRef}> 
      {!session && <Alert
        message={t(`create-experience.messages.important.message`)}
        description={
          <div>
            <p>{t(`create-experience.messages.important.description`)}</p>
            
            <Button size="small" type="primary"  onClick={() =>navigate("/login", {})}>
              Login
            </Button>
          </div>
        }
        type="info"
      />}
      {!sent.loaded &&
      <div className='Subscribe-header'>
        <div className='container'>
          <h1>{t(`create-experience.form-title`)}</h1>
          {<CreateExperience paymentInfo={paymentInfo} onSubmit={handleSubmit}/>}
        </div>
      </div>
      }
      {sent.loaded && !sent.error &&
      <Result
        status="success"
        title={t(`create-experience.messages.success.title`)}
        subTitle={t(`create-experience.messages.success.subtitle`)}
        extra={[
          <Button onClick={() => setSent(false)} type="primary" key="console">
            {t(`create-experience.messages.success.button`)}
          </Button>,
        ]}
      />
      }
      {sent.loaded && sent.error &&
      <Result
        status="error"
        title={t(`create-experience.messages.error.title`)}
        extra={[
          <Button onClick={() => setSent({error: false, loaded: false})} type="primary" key="console">
            {t(`create-experience.messages.error.button`)}
          </Button>,
        ]}
      />
      }
  </div>    
  );
}
export default NewExperiencePage;