export const setSession = (token) => {
  localStorage.setItem('session', token)
}

export const destroySession = () => {
  localStorage.removeItem('session')
  window.location.href = '/'
}

export const getSession = () => {
  const session = localStorage.getItem('session')
  return session ? session : ''
}