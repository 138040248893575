import { useState } from 'react';
import './Subscribe.css';
import { useLocation } from "react-router-dom";
import { useEffect, useRef } from "react";
import { CreateSubscription } from '../components/subscription/create/index.tsx'
import { getSession } from '../utils/session'
import { Alert, Button, Spin } from 'antd'
import { useNavigate } from "react-router-dom";

function BuyPage() {
  const navigate = useNavigate()
  const SERVER_URL = process.env.REACT_APP_SERVER_URL;
  const [loading, setLoading] = useState(false);
  const [session, setSession] = useState(false);
  const [message, setMessage] = useState(null);
  const {state} = useLocation();
  
  const pageRef = useRef();
  
  useEffect(() => {
    window.parent.postMessage(
      {type: 'resize', height: pageRef.current.scrollHeight}, 
      process.env.REACT_APP_PARENT_DOMAIN
    );
    if(getSession()!=='') {
      setLoading(true);
      buy({})
      console.log('redirect')
    }
  }, [])

  if (!state || !state.id ) {
    console.log('redirect')
    window.location = '/'
  }

  const buy = (form) => {
    setLoading(true)

    fetch(`${SERVER_URL}/buy/experience/${state.id}`,{
      method: 'POST',
      headers:{
        'Content-Type': 'application/json',
        'auth-token': getSession()
      },
      body: JSON.stringify({
        ...form
      })
    })
    .then(response => {
      if(response.status===409) {
        setMessage({message: 'You already have an active subscription', type: 'error'})
      }
      if(response.status===200) {
        setMessage({message: 'Please check your email', type: 'success'})
      }
      return response.json()
    })
    .then(({data}) => {  
      setLoading(false)
      window.location.href = data.links.filter(link => link.rel === 'approve')[0].href
    }).catch(() => {
      setLoading(false)
    });
  }
  
  return (
    <div ref={pageRef} className='Subscribe-header'>
      <Spin spinning={loading} ip="Loading...">
        <div className='container'>
          <h1>Subscription </h1>
          {!session && <Alert
            message="Important"
            description={
              <div>
                <p>If you already have an account please log in</p>
                
                <Button size="small" type="primary"  onClick={() =>navigate("/login", {})}>
                  Login
                </Button>
              </div>
            }
            type="info"
          />}
          {!session && <p>Or create an account</p>}
          {!session && <CreateSubscription onSubmit={buy} shortForm={false}/> }
          { message &&
            <Alert message={message.type === 'error' ? 'Error': 'Success'} type={message.type} description={message.message} />
          }
        </div>
      </Spin>
    </div>
  );
}

export default BuyPage;
