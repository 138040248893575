import ImageKit from "imagekit-javascript"

const imagekit = new ImageKit({
  publicKey: process.env.REACT_APP_IMAGEKIT_PUBLIC_KEY,
  privateKey : process.env.REACT_APP_IMAGEKIT_PRIVATE_KEY,
  urlEndpoint : process.env.REACT_APP_IMAGEKIT_URL_ENDPOINT,
})

export const imageUrl = (url, height, width) => {
  const path = url.split('/').pop()
  return imagekit.url({
    path : path,
    urlEndpoint : process.env.REACT_APP_IMAGEKIT_URL_ENDPOINT,
    transformation : [{ height, width, focus: 'top' }]
  });
}