import { useNavigate, useSearchParams } from 'react-router-dom';
import { useEffect, useRef, useState } from "react";

function App() {
  const pageRef = useRef();
  const navigate = useNavigate();

  const [descriptions, setDescriptions] = useState([]);
  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {    
    window.parent.postMessage(
      {height: pageRef.current.scrollHeight}, 
      process.env.REACT_APP_PARENT_DOMAIN
    );
    const lang = searchParams.get('lang') || 'EN'
    // fetch(`http://localhost:4000/editor/subscription-all?lang=${lang}`)
    fetch(`https://spanishtimezone-server.herokuapp.com/editor/subscription-all?lang=${lang}`)
      .then(response => response.json())
      .then(response => response.list)
      .then(list => setDescriptions(list))
      .then(() => {
        setTimeout(() => {
          window.parent.postMessage(
            {height: pageRef.current.scrollHeight}, 
            process.env.REACT_APP_PARENT_DOMAIN
          )
        }, 300)
      })
  }, [])

  const onImageLoad = () => {
    setTimeout(() => {
      window.parent.postMessage(
        {height: pageRef.current.scrollHeight}, 
        process.env.REACT_APP_PARENT_DOMAIN
      )
    }, 300)
  }
  
  return (
    <div ref={pageRef} className="App">
      <div className='App-header'>
        <div className='container'>
        <h1 className='main-plan-title'><strong>What kind of membership program do we offer?</strong></h1>
          <div className='table-container'>
            <table style={{borderColor: '#04b4ea', tableLayout: 'fixed'}}>
              <thead className='border-table'>
                <tr className='border-table'>
                  {descriptions[0] && descriptions[0].map(({name}, index) => (
                    <td key={index} className='border-table' style={{backgroundColor: '#04b4ea'}}>
                      <h3 className='plan-title'>
                        <strong>
                          {name.substring(0, name.lastIndexOf(' ')).split(' ').map(e => <>{e} <br/> </>)}
                        </strong>
                        {name.slice(name.lastIndexOf(' ') + 1)}
                      </h3>
                    </td>
                  ))}
                </tr>
              </thead>
              <tbody className='border-table'>
                {descriptions.map((row, rowIndex) => {
                  return (
                    <tr key={rowIndex}>
                      {row.map((desc, index) => (
                        <td data-title={desc.name} key={index} className='feature border-table'>
                          <p dangerouslySetInnerHTML={{__html: desc.html}}></p>
                        </td>
                      ))}
                    </tr>
                  )
                })}
              </tbody>
            </table>
          </div>
          <div>
            <div className='call-to-action'>
              <button className='button' onClick={() => navigate("/detail")}>Become a Spanishtimezone member</button>
            </div>

          </div>
            <div style={{display:'flex', justifyContent:'center'}}>
              <img
                src={'https://ik.imagekit.io/devkyngpq/foto2_4Hv5wP1mUr.jpg?updatedAt=1680362470488'}
                style={{width: '800px'}}
                onLoad={onImageLoad}
              />
            </div>
          
        </div>
      </div>
    </div>
  );
}

export default App;
