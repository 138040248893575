import React from 'react';
import ReactDOM from 'react-dom/client';
import {
  BrowserRouter,
  Routes,
  Route,
} from "react-router-dom";
import './index.css';
import App from './App';
import Subscribe from './pages/Subscribe';
import Details from './pages/Details';
import ExperincesPage from './pages/Experiences';
import ExperinceDatailsPage from './pages/ExperienceDetails';
import BuyPage from './pages/Buy';
import SuccessPage from './pages/Success';
import FailurePage from './pages/Failure';
import NewExperience from './pages/NewExperience';
import PromotionalExperincesPage from './pages/PromtionalExperiences';
import ConfirmEmailPage from './pages/ConfirmEmail';
import RatePage from './pages/Rate';
import CustomPage from './pages/CustomPages';
import BlogPage from './pages/Blog';

import LoginPage from './pages/Login';
import reportWebVitals from './reportWebVitals';
import { I18nextProvider } from 'react-i18next';
import i18next from 'i18next';
import translation_en from './translations/en.json'
import translation_ger from './translations/ger.json'
import 'antd/dist/antd.css';

i18next.init({
  interpolation: { escapeValue: false },
  lng: 'en',
  fallbackLng: 'en',
  debug: true,
  resources: {
    en: { translation: translation_en },
    ger: { translation: translation_ger }
  }
})

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <I18nextProvider i18n={i18next}>
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<App />} />
        <Route path="detail" element={<Details />} />
        <Route path="subscribe" element={<Subscribe />} />
        <Route path="experiences" element={<ExperincesPage />} />
        <Route path="experience" element={<ExperinceDatailsPage />} />
        <Route path="experience/:experienceId" element={<ExperinceDatailsPage />} />
        <Route path="experience/new" element={<NewExperience />} />
        <Route path="experience/buy" element={<BuyPage />} />
        <Route path="subscription/success" element={<SuccessPage />} />
        <Route path="subscription/failure" element={<FailurePage />} />
        <Route path="promotional-experiences" element={<PromotionalExperincesPage />} />
        <Route path="confirm-email" element={<ConfirmEmailPage />} />
        <Route path="login" element={<LoginPage />} />
        <Route path="rate" element={<RatePage />} />
        <Route path="custom-page/:name" element={<CustomPage />} />
        <Route path="blog/:id" element={<BlogPage />} />
      </Routes>
    </BrowserRouter>
  </I18nextProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
