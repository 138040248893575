import React, { Fragment } from 'react'
import { Button, Row, Input, Form } from 'antd'
import { useTranslation } from 'react-i18next';
import './ConfirmEmail.css';
import logo from '../logo.png'
import { useNavigate } from "react-router-dom";
import { setSession } from '../utils/session'

const FormItem = Form.Item

const SERVER_URL = process.env.REACT_APP_SERVER_URL;
const basePath = `${SERVER_URL}/user`

const catchError = (response) => {
  if(response.status!==200) {
    throw new Error(response.status)
  }
  return response
}

const login = (params) => {
  return fetch(`${basePath}/login`,{
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(params)
  })
  .then(catchError)
  .then((response) => (response.json()))
}


const LoginPage = () => {
  const { t } = useTranslation();
  let navigate = useNavigate();

  const handleOk = ({email, password}) => {
    const params = {email, password}
    login(params)
      .then(({data}) => {
        setSession(data.token)
        navigate('/subscribe')
      })
  }
  return (
  <Fragment>
    <div className={'confirm-container'}>
      <div className={'confirm-logo'}>
        <img className={'confirm-img'} alt="logo" src={logo} />
        <span>Login</span>
      </div>
      <div className={'confirm-form'}>
        <Form
          onFinish={handleOk}
          >
          <FormItem name="email" rules={[
            { required: true, message: t(`login.email.error.required`)},
            ]}>
            <Input type='email' placeholder={t(`login.email.placeholder`)}/>
          </FormItem>

          <FormItem name="password" dependencies={['password']} rules={[
            { required: true, message: t(`login.password.error.required`)},
            { min: 6, message: t(`login.password.error.min`)},
            ]}>
            <Input type='password' placeholder={t(`login.password.placeholder`)}/>
          </FormItem>
          <Row>
            <Button
              type="primary"
              htmlType="submit"
            >
              {t(`login.button`)}
            </Button>
          </Row>
        </Form>
      </div>
    </div>
  </Fragment>
  )
}

export default LoginPage;
