import React, { useEffect } from 'react';
import { Button, Select, Form as AntForm, Input, InputNumber, Typography, DatePicker, Upload, Divider, Rate, Radio, Space } from 'antd';
import { MinusCircleOutlined, PlusOutlined, UploadOutlined } from '@ant-design/icons';
import type { FormInstance } from 'antd/es/form';
import style from './style.module.css';
import type { ValidatorRule } from 'rc-field-form/lib/interface';

type Option = {
  label: string;
  value: string;
}

interface Field {
  type: 'input' | 'empty' | 'rate' | 'radio' | 'password' | 'text-area' | 'numeric' | 'price' | 'selector'| 'date' | 'dynamic-date' | 'add-item' | 'upload' | 'divider';
  name: string;
  label: string;
  value: string;
  placeholder: string;
  options: Array<Option>;
  rules: Array<ValidatorRule>,
  normalize?: Function,
  min: number,
  max: number,
  disclaimer: string,
  isDisclaimerTop?: boolean,
  disabled: boolean,
  mode?: string
}

interface Action {
  label: string;
  action: Function;
}

interface Actions { 
  submit: Action
  cancel?: Action
}

interface Props {
  fields: Array<any>;
  initialValues?: Object;
  layout?: 'inline' | 'horizontal' | 'vertical';
  actions: Actions;
  loading: boolean;
}

export const Form: React.FC<Props> = ({fields, initialValues, layout, actions, loading}) => {
  const formItemLayout = { labelCol: { span: 24 }, wrapperCol: { span: 24} }
  const [form] = AntForm.useForm();
  const formRef = React.createRef<FormInstance>();

  useEffect(() => {
    form.resetFields()
  })

  const onFinish = (values: any) => {
    actions.submit.action(values)
  };

  const cancel = () => {
    actions.cancel && actions.cancel.action()
  }

  return (
    <AntForm
      {...formItemLayout}
      layout={layout ? layout: 'inline'}
      form={form}
      onFinish={onFinish}
      name="form"
      ref={formRef}
      initialValues={initialValues}
    >
      {fields.map(({type, name, label, placeholder='', options=[], rules=[], disclaimer, isDisclaimerTop=false, disabled=false, min, max, normalize, mode }: Field, index) => {
        if(type === 'input') {
          return (
            <AntForm.Item key={index} label={label} className={style.formItem}>
              <AntForm.Item
              name={name}
              rules={rules}
              normalize={(value, prev, values) => {
                if(normalize) return normalize(value, prev, values)
                return value
              }}>
                <Input placeholder={placeholder} className={style.input} disabled={disabled}/>
              </AntForm.Item>
              {disclaimer &&
                <div className={style.disclaimer}>
                  <Typography.Text italic={true}>{disclaimer}</Typography.Text>
                </div>
                }
            </AntForm.Item>
            )
        }

        if(type === 'empty') {
          return (
            <AntForm.Item key={index} className={style.formItem}>
            </AntForm.Item>
            )
        }

        if(type === 'rate') {
          return (
            <AntForm.Item key={index} label={label} className={style.formItem}>
              <AntForm.Item
              name={name}
              rules={rules}
              normalize={(value, prev, values) => {
                if(normalize) return normalize(value, prev, values)
                return value
              }}>
                <Rate />
              </AntForm.Item>
              {disclaimer &&
                <div className={style.disclaimer}>
                  <Typography.Text italic={true}>{disclaimer}</Typography.Text>
                </div>
              }
            </AntForm.Item>
            )
        }

        if(type === 'radio') {
          return (
            <AntForm.Item name={name} rules={rules} key={index} label={label} className={style.formItem}>
              <Radio.Group>
              <Space direction="vertical">
              { options.map(({value, label}, index) => (
                  <Radio className={style.formRadio} key={index} value={value}>{label}</Radio>
                ))
              }
              </Space>
              </Radio.Group>
            </AntForm.Item>
          )
        }

        if(type === 'password') {
          return (
            <AntForm.Item key={index} label={label} className={style.formItem}>
              <AntForm.Item
              name={name}
              rules={rules}
              normalize={(value, prev, values) => {
                if(normalize) return normalize(value, prev, values)
                return value
              }}>
                <Input type='password' placeholder={placeholder} className={style.input} disabled={disabled}/>
              </AntForm.Item>
              {disclaimer &&
                <div className={style.disclaimer}>
                  <Typography.Text italic={true}>{disclaimer}</Typography.Text>
                </div>
              }
            </AntForm.Item>
            )
        }

        if(type === 'text-area') {
          return (
            <AntForm.Item key={index} label={label} className={style.formItem}>
              <AntForm.Item name={name} rules={rules} >
                <Input.TextArea placeholder={placeholder} className={style.input} disabled={disabled} rows={5}/>
              </AntForm.Item>
              {disclaimer &&
                <div className={style.disclaimer}>
                  <Typography.Text italic={true}>{disclaimer}</Typography.Text>
                </div>
              }
            </AntForm.Item>
            )
        }

        if(type === 'numeric') {
          const add = () => {
            const newValue = formRef.current!.getFieldValue(name) + 1
            if(newValue <= max || max === undefined) {
              formRef.current!.setFieldsValue({[name]: newValue})
            }
          }
          const substrack = () => {
            const newValue = formRef.current!.getFieldValue(name) - 1
            if(newValue >= min || min === undefined) {
              formRef.current!.setFieldsValue({[name]: newValue})
            }
          }
          return (
            <AntForm.Item key={index} label={label} className={style.formItem}>
              <AntForm.Item name={name} rules={rules} >
                <InputNumber 
                  className={style.input}
                  placeholder={placeholder}
                  min={min}
                  max={max}
                  disabled={disabled}
                />
              </AntForm.Item>
              <Button onClick={add}>+</Button>
              <Button onClick={substrack}>-</Button>
              {disclaimer &&
                <div className={style.disclaimer}>
                  <Typography.Text italic={true}>{disclaimer}</Typography.Text>
                </div>
              }
            </AntForm.Item>
            )
        }

        if(type === 'price') {
          return (
            <AntForm.Item key={index} label={label} className={style.formItem}>
              <AntForm.Item name={name} rules={rules} >
                <InputNumber 
                  className={style.input}
                  placeholder={placeholder}
                  formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, '.')}
                  parser={value => parseInt(value!.replace(/\$\s?|(\.*)/g, ''))}
                  min={min}
                  max={max}
                  disabled={disabled}
                />
              </AntForm.Item>
              {disclaimer &&
                <div className={style.disclaimer}>
                  <Typography.Text italic={true}>{disclaimer}</Typography.Text>
                </div>
              }
            </AntForm.Item>
            )
        }

        if(type === 'selector') {
          return (
            <AntForm.Item key={index} label={label} className={style.formItem}>
                <AntForm.Item noStyle name={name} rules={rules}>
                  <Select className={style.input} placeholder={placeholder} disabled={disabled}>
                    {options.map(({value, label}, index) => (
                      <Select.Option key={index} value={value}>{label}</Select.Option>
                    ))}
                  </Select>
                </AntForm.Item>
                {disclaimer &&
                <div className={style.disclaimer}>
                  <Typography.Text italic={true}>{disclaimer}</Typography.Text>
                </div>
                }
            </AntForm.Item>
          )
        }

        if(type === 'add-item') {
          return (
            <AntForm.Item key={index} label={label} className={style.formItem}>
              <AntForm.Item noStyle name={name} rules={rules}>
                <Select className={style.input} placeholder={placeholder} disabled={disabled} mode="multiple" allowClear>
                  {options.map(({value, label}, index) => (
                    <Select.Option key={index} value={value}>{label}</Select.Option>
                  ))}
                </Select>
                </AntForm.Item>
                {disclaimer &&
                <div className={style.disclaimer}>
                    <Typography.Text>{disclaimer}</Typography.Text>
                </div>
                }
            </AntForm.Item>
            )
        }
        if(type === 'date') {
          return (
            <AntForm.Item 
              key={index}
              name={name}
              label={label}
              className={style.formItem}
              validateTrigger={['onChange', 'onBlur']}
              rules={rules}
            >
              <DatePicker placeholder={placeholder} style={{ width: '60%' }}/>
            </AntForm.Item>
          )
        }
        if(type === 'dynamic-date') {
          const formItemLayoutWithOutLabel = {
            wrapperCol: {
              xs: { span: 24, offset: 0 },
              sm: { span: 20, offset: 4 },
            },
          };
          return (
            <AntForm.Item key={index} label={label} className={style.formItem}>
              <AntForm.List name={name} rules={[...rules, {
                  validator: async (_, names) => {
                    if (!names || names.length < 1) {
                      return Promise.reject(new Error('Must add 1 date'));
                    }
                  },
                },
              ]}>
              {(fields, { add, remove }, { errors }) => (
                <>
                  {fields.map((field, index) => (
                    <AntForm.Item
                      required={false}
                      key={field.key}
                      
                    >
                      <AntForm.Item
                        {...field}
                        
                        validateTrigger={['onChange', 'onBlur']}
                        rules={rules}
                        noStyle
                      >
                        <DatePicker className={style.date} placeholder={placeholder} style={{ width: '60%' }}/>
                        
                      </AntForm.Item>
                        {fields.length > 1 ? (
                          <MinusCircleOutlined
                            className="dynamic-delete-button"
                            onClick={() => remove(field.name)}
                          />
                        ) : null}
                    </AntForm.Item>
                  ))}
                  <AntForm.Item>
                    <Button
                      type="dashed"
                      onClick={() => add()}
                      style={{ width: '60%' }}
                      icon={<PlusOutlined />}
                    >
                      Agregar Fecha
                    </Button>
                  
                    <AntForm.ErrorList errors={errors} />
                  </AntForm.Item>
                </>
              )}
              </AntForm.List>
              {disclaimer &&
                <div className={style.disclaimer}>
                  <Typography.Text italic={true}>{disclaimer}</Typography.Text>
                </div>
              }
            </AntForm.Item>
          )
        }
        if (type === 'upload') {
          const normFile = (e: any) => {
            console.log('Upload event:', e);
            if (Array.isArray(e)) {
              return e;
            }
            if (e) {
              return e.fileList;
            }
            return 
          };

          return(
            <AntForm.Item key={index} label={label} className={style.formItem}>
              <AntForm.Item noStyle name={name} rules={rules}
                valuePropName="fileList"
                getValueFromEvent={normFile}
              >
                <Upload name="file" action="http://localhost:4000/media" >
                  <Button icon={<UploadOutlined />}>{placeholder}</Button>
                </Upload>
              </AntForm.Item>
              {disclaimer &&
                <div className={style.disclaimer}>
                  <Typography.Text italic={true}>{disclaimer}</Typography.Text>
                </div>
              }
            </AntForm.Item>
          )
        }
        if (type === 'divider') {
          return (<Divider style={{border: '1px solid #04b4ea', backgroundColor: '#04b4ea'}} orientation="left" key={index}>{label}</Divider>)
        }
      })}

      <div className={style.submitContainer}>
        <Button loading={loading} type="primary" htmlType="submit">{actions.submit.label}</Button>
        {actions.cancel && 
        <Button loading={loading} type="primary" danger className={style.cancelButton} onClick={cancel}>
          {actions.cancel.label}
        </Button> 
        }
      </div>
      
    </AntForm>
  );
};
