import './ExperienceDetails.css';
import { useEffect, useRef, useState } from "react";
import { RateExperience } from '../components/rate/index.tsx';
import { Result, Button } from 'antd'

const RatePage = () => {
  const [sent, setSent] = useState(false)
  const pageRef = useRef();
  useEffect(() => {    
    window.parent.postMessage(
      {type: 'resize', name: 'new-experience', height: pageRef.current.scrollHeight}, 
      process.env.REACT_APP_PARENT_DOMAIN
    );
  }, [])

  const handleSubmit = () => setSent(true)
  
  return (
    <>
      {!sent && 
      <div ref={pageRef} className='Subscribe-header'>
        <div className='container'>
          <h1>Rate Experience</h1>
          <RateExperience onSubmit={handleSubmit}/>
        </div>
      </div>
      }
      {sent && 
      <Result
        status="success"
        title="Successfully Rate sent"
        subTitle="Check your email to know the next steps"
        extra={[
          <Button onClick={() => setSent(false)} type="primary" key="console">
            Go Back
          </Button>,
        ]}
      />
      }
  </>    
  );
}
export default RatePage;