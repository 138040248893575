import React, { Fragment } from 'react'
import { Button, Row, Input, Form } from 'antd'
import { useTranslation } from 'react-i18next';
import './ConfirmEmail.css';
import logo from '../logo.png'
import { useNavigate } from "react-router-dom";
const FormItem = Form.Item
const SERVER_URL = process.env.REACT_APP_SERVER_URL;

const basePath = `${SERVER_URL}/user`

const catchError = (response) => {
  if(response.status!==200) {
    throw new Error(response.status)
  }
  return response
}

const confirmEmail = ({ password }) => {
  const params = new URLSearchParams(window.location.search)
  let token =  params.get('token')
  if (!token) { token = ''}
  return fetch(`${basePath}/confirm-email`,{
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'auth-token': token
    },
    body: JSON.stringify({ password })
  })
  .then(catchError)
  .then((response) => (response.json()))
}


const ConfirmEmailPage = () => {
  const { t } = useTranslation();
  let navigate = useNavigate();

  const handleOk = (values) => {
    const params = {password: values.password}
    confirmEmail(params)
      .then(({data}) => {
        window.location.href = data.links.filter(link => link.rel === 'approve')[0].href
      })
  }
  return (
  <Fragment>
    <div className={'confirm-container'}>
      <div className={'confirm-logo'}>
        <img className={'confirm-img'} alt="logo" src={logo} />
        <span></span>
      </div>
      <div className={'confirm-form'}>
        <Form
          onFinish={handleOk}
          >
          <FormItem name="password" rules={[
            { required: true, message: t(`confirm-email.password.error.required`)},
            { min: 6, message: t(`confirm-email.password.error.min`)},
            ]}>
            <Input type='password' placeholder={t(`confirm-email.password.placeholder`)}/>
          </FormItem>

          <FormItem name="check-password" dependencies={['password']} rules={[
            { required: true, message: t(`confirm-email.check-password.error.required`)},
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || getFieldValue('password') === value) {
                  return Promise.resolve();
                }
                return Promise.reject(t(`confirm-email.check-password.error.match`));
              },
            }),
            ]}>
            <Input type='password' placeholder={t(`confirm-email.check-password.placeholder`)}/>
          </FormItem>
          <Row>
            <Button
              type="primary"
              htmlType="submit"
            >
              {t(`confirm-email.button`)}
            </Button>
          </Row>
        </Form>
      </div>
    </div>
  </Fragment>
  )
}

export default ConfirmEmailPage;
