import React, { useState } from 'react';
import { Form }  from '../form/index.tsx';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';

const SERVER_URL = process.env.REACT_APP_SERVER_URL;

const getFields = (t: Function) => [
  {
    type: 'input',
    label: t(`name.label`),
    name: 'name',
    placeholder: t(`name.placeholder`),
    rules: [{ required: true, message: t(`name.error.required`)}]
  }, {
    type: 'rate',
    label: t(`rate.label`),
    name: 'rate',
    placeholder: t(`rate.placeholder`),
    rules: [{ required: true, message: t(`rate.error.required`)}]
  }, {
    type: 'text-area',
    label: t(`comment.label`),
    name: 'comment',
    placeholder: t(`comment.placeholder`),
  }
]

const getActions = (t: Function, onLoad: Function, action: Function, onSubmit: Function, onCancel: Function) => ({
  submit: {
    label: t(`submit.label`),
    action: (values: any) => {
      console.log(values)
       onLoad()
       action(values)
        .then(() => onSubmit())
    }
  },
})

interface Props {
  onSubmit: Function
  onCancel: Function
}


export const RateExperience: React.FC<Props> = ({ onSubmit, onCancel }) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false)
  const [searchParams, setSearchParams] = useSearchParams();

  const rateExperience = (form) => {
    setLoading(true)
    return fetch(`${SERVER_URL}/rate`,{
      method: 'POST',
      headers:{
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        ...form,
        email: '',
        experienceId: searchParams.get('id'),
      })
    })
    .then(response => response.json())
    .then(({data}) => {
      setLoading(false)
      // redirect
    })
    .catch(() => {
      setLoading(false)
    });
  }

  const onLoad = () => setLoading(true)
  const onLoadedAndSubmit = () => {
    setLoading(false)
    onSubmit()
  }

  const fields = getFields((key:string) => t(`rate-experience.${key}`))
  const actions = getActions((key:string) => t(`rate-experience.${key}`), onLoad, rateExperience, onLoadedAndSubmit, onCancel)
  
  const onSelectInputMap = (form: any, value: any) => {
    form.setFieldsValue({
      city: value.municipality,
      state: value.state,
    });
  }
  return (
    <Form
      layout='horizontal'
      fields={fields}
      actions={actions}
      onSelectInputMap={onSelectInputMap}
      loading={loading}
    />
  );
};
