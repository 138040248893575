import './Experiences.css';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useState, useEffect, useRef } from 'react';
import { imageUrl } from '../utils/image';
import { Pagination, Layout } from 'antd';

const { Footer } = Layout;

const footerStyle: React.CSSProperties = {
  textAlign: 'center',
  background: '#f0f2f5',
  color: 'rgba(0,0,0,.85)',
  fontSize: '14px',
  padding: '24px 50px',
};

const Experience = (props) => {
  const {
    id,
    name,
    description,
    media,
    price,
    currency_code,
    updateSize,
    price_message,
    experience_dates,
    inscription_deadline,
    monitor,
    available,
    hours,
    extra_info,
    level,
    summary_info,
    sale
  } = props
  
  const firstImage = media.filter((resource) => resource.type === 'image')[0]
  const image = firstImage ? firstImage.source: 'https://ik.imagekit.io/devkyngpq/default-img_02wKwNjUU.jpeg'
  const navigate = useNavigate();   
  
  return(
    <div style={{ position: 'relative'}}>
      {sale && sale !== 'undefined' &&
      <div style={{
          backgroundColor: 'red',
          color: 'white',
          position: 'absolute',
          right: '-10px',
          top: '-10px',
          zIndex: '999',
          border: 'none',
          padding: '5px',
          borderRadius: '6px',
          margin: '0 10px',
        }}>SALE</div>
      }
      <div className='experience-card'>
        <img className='experience-img' src={imageUrl(image, 450, 300)} onLoad={updateSize}/>
        <div className='experience-info'>
          <div>
            <h2 className='experience-title'>{name}</h2>
            <p className='experience-text' >{description}</p>
            { monitor !== '' && <p className='experience-text' >Monitor: {monitor}</p> }
          </div>
          <div className='experience-cta'>
            <button 
              className='button'
              onClick={() => {
                console.log('props: ', props)
                navigate("/experience", {
                  state: {
                    id,
                    name,
                    description,
                    media,
                    price,
                    currency_code,
                    price_message,
                    experience_dates,
                    inscription_deadline,
                    monitor,
                    available,
                    hours,
                    extra_info,
                    level,
                    summary_info,
                    sale
                  }
                })
              }
              }>
                GET IT
              </button>
          </div>
        </div>
      </div>
    </div>
  )
}

const ExperincesPage = () => {
  const SERVER_URL = process.env.REACT_APP_SERVER_URL;
  const [experiences, setExperiences] = useState([])
  const [page, setPage] = useState(1)
  const [nElements, setNElements] = useState(10)
  const [searchParams, setSearchParams] = useSearchParams();

  const pageRef = useRef();
  
  const updateSize = () => {
    window.parent.postMessage(
      {type: 'resize', height: pageRef.current.scrollHeight}, 
      process.env.REACT_APP_PARENT_DOMAIN
    );
  }
  
  const getPage = (page) => {
    setPage(page)
    const level = searchParams.get('level')
    const lang = searchParams.get('lang') || 'EN'
    let url = `${SERVER_URL}/experience/approved?page=${page}&lang=${lang}`
    if(level) {
      url = url.concat(`&level=${level}`)
    }
    updateSize()
    fetch(url, {
      method: 'GET',
    })
    .then((response) => response.json())
    .then((response) => { 
      setExperiences(response.response)
      setNElements(response.count)
    })
    .then(updateSize)
  }
  
  useEffect(() => {
    getPage(page)
  }, [])

  const onChangePage = (page) => {
    console.log('page: ', page)
    getPage(page)

  }
  return (
    <div ref={pageRef} className="App experience" style={{minHeight: 'initial'}}>
      
      <div className='experience-body' style={{flexDirection: 'column'}}>
        <div className='container experience-container' style={{minHeight: 'initial'}}>
          {experiences.map((experience) => (
            <Experience {...experience} updateSize={updateSize} />
          ))}
        </div>
        {nElements > 10 && 
        <div style={footerStyle}>
          <Pagination defaultCurrent={page} total={nElements} onChange={onChangePage}/>
        </div>
        }
      </div>
    </div>
  )  
}

export default ExperincesPage;
